@import "ui/styles/2-tools/tools.media-query.scss";

.Footer {
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	color: var(--color-black);
	background-color: var(--color-grey-lightest);
	padding: var(--spacing--5xl) 0 var(--spacing--xl) 0;

	@include mq("lg") {
		padding: var(--spacing--5xl) 0 var(--spacing--4xl) 0;
		flex-direction: column;
	}
}

.Footer_topSection {
	margin-bottom: 4.8rem;
	display: flex;
	flex-direction: column;
	gap: 20rem;
	@include mq("lg") {
		margin-bottom: 41.3rem;
		flex-direction: row;
		justify-content: space-between;
	}
}

.Footer_Link {
	display: flex;
	align-items: flex-end;
	gap: 1rem;
}

.Footer_details {
	display: flex;
	justify-content: space-between;
	flex-direction: column-reverse;
	gap: 8rem;

	@include mq("lg") {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.Footer_logo {
	max-width: 30rem;
	width: 100%;
	height: 7.8rem;
	margin-right: 4rem;
	color: currentColor;
}

.Footer_information {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 2rem;

	@include mq("lg") {
		gap: 4rem;
		flex-wrap: nowrap;
	}
}

.Footer_information > * {
	@include mq("xl") {
		min-width: 13rem;
	}

	&:nth-child(1) {
		order: 1;
		flex-basis: 100%;

		@include mq("sm") {
			flex-basis: calc(50% - 1rem);
		}

		@include mq("lg") {
			order: 1;
		}
	}

	&:nth-child(2) {
		flex-basis: 100%;
		order: 5;
		@include mq("md") {
			flex-basis: calc(50% - 1rem);
		}
		@include mq("lg") {
			order: 2;
		}
	}

	&:nth-child(3) {
		flex-basis: 100%;
		order: 5;
		@include mq("md") {
			flex-basis: calc(50% - 1rem);
		}
		@include mq("lg") {
			order: 3;
		}
	}

	&:nth-child(4) {
		order: 2;
		flex-basis: 100%;
		padding-top: 3.2rem;

		@include mq("sm") {
			flex-basis: calc(50% - 1rem);
			align-self: flex-end;
			padding-top: 0;
		}

		@include mq("lg") {
			order: 4;
		}
	}

	&:nth-child(5) {
		flex-basis: 100%;
		order: 5;
		@include mq("md") {
			flex-basis: calc(50% - 1rem);
		}
		@include mq("lg") {
			order: 5;
			align-self: flex-end;
		}
	}

	&:nth-child(6) {
		@include mq("lg") {
			order: 6;
		}
	}
}

.Footer_extraInformation {
	padding-top: 3.2rem;
	display: flex;
	flex-direction: column;

	@include mq("md") {
		padding-top: 0;
	}

	.Link_link {
		display: block;
	}
}

// Theme: Kogp
[data-theme="kogp"] {
	.Footer {
		border-top: 2px solid var(--color-grey-lightest);
		background-color: var(--color-white);
	}
	.Footer_logo {
		color: var(--color-primary);
		align-self: start;
	}
	.Footer_topSection {
		@include mq("lg") {
			margin-bottom: 0;
		}
	}
	.Footer_information {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		> * {
			&:nth-child(4) {
				padding-top: 0;
			}
		}
	}
	.Footer h3 {
		color: var(--color-grey-light);
		font-size: 1em;
		font-weight: 400;
	}
}
