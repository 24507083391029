@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.OfficeCard_heading {
	@include text-link--sm;
	margin-bottom: 0.9rem;
	margin-top: 3.2rem;

	@include mq("md") {
		margin-top: 0;
	}
}

.OfficeCard_textbox {
	@include paragraph;
}

// Theme: Kogp
[data-theme='kogp'] {
	.OfficeCard_heading {
		margin-top: auto;
		margin-bottom: 0;
	}
}