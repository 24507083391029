@import "ui/styles/2-tools/tools.media-query.scss";

/* ------------------------------------ *\
		tools.typography-mixins
\* ------------------------------------ */

@mixin heading--6xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--2xl);
	line-height: var(--line-height--sm);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--4xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--6xl);
	}
}

@mixin heading--5xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--md);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--3xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--5xl);
	}
}

@mixin heading--4xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--xs);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--3xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--4xl);
	}
}

@mixin heading--3xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--xs);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--3xl);
	}
}

@mixin heading--2xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--2xl);
	}
}

@mixin heading--xl {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--md);
	letter-spacing: var(--letter-spacing-heading);

	@include mq("md") {
		font-size: var(--font-size--xl);
	}

	@include mq("lg") {
		font-size: var(--font-size--xl);
	}
}

@mixin body--lg-bold {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--black);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--lg-med {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--lg-reg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--lg-lig {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--light);
	font-size: var(--font-size--lg);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--md-med {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--md-reg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--reg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing-body);
}

@mixin body--sm-reg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-heading);
}

@mixin manchet {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--md);
}

@mixin paragraph {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin paragraph-md {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--semibold);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin paragraph--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin text-link--lg {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--md);
	line-height: var(--line-height--lg);
	letter-spacing: var(--letter-spacing-body);
}

@mixin text-link--sm {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--bold);
	font-size: var(--font-size--sm);
	line-height: var(--line-height--xl);
	letter-spacing: var(--letter-spacing-body);
}

@mixin formfield-label {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	color: currentColor;
	width: 100%;
	cursor: pointer;
}
