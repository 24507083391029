@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ContactCard .ContactCard_heading {
	@include text-link--sm;
	margin-bottom: 0.9rem;
	margin-top: 3.2rem;

	@include mq("md") {
		margin-top: 0;
	}
}

.ContactCard_textbox {
	@include paragraph;
}

.ContactCard_text {
	display: flex;
	flex-direction: column;
}
